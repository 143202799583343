import React from "react"
import SEO from '../../components/seo'

import Osirase from '../../layouts/osirase'


export default () => (


  <Osirase>

	<SEO title="プライバシーポリシー"/>

	<main id="wrapper" class="seigen">

<h1>特定商取引法に基づく表記</h1>
<ul>
<li>販売業者名：北川祐季</li>
<li>販売責任者：北川祐季</li>
<li>URL：https://elvn.tokyo</li>
<li>所在地：604-8187京都府京都市中京区御池通東洞院東入笹屋町436 永和御池ビル606号</li>
<li>メールアドレス：i@elvn.tokyo</li>
<li>電話番号：05053291400</li>
<li>販売価格：商品ページに記載の通りです</li>
<li>お申し込み方法：商品ページ内の購入フォームよりお申込みください</li>
<li>お支払い方法：クレジットカード、PayPal、銀行振込</li>
<li>送料：デジタル商品につき、送料はかかりません</li>
<li>商品以外の必要料金：銀行振込の場合、振込手数料</li>
<li>商品引渡し方法：メール記載URLよりご利用及びダウンロード</li>
<li>商品の引渡し時期：決済完了後、24時間以内にメールにてご案内</li>
<li>不良品について：商品URLより正常に利用及びダウンロードできなかった場合は、時間を空けて再度行ってください。再度行っても正常にできなかった場合は、記載のメールアドレスにお問い合わせください。速やかに問題の解決を図り、解決に至らない場合でも代替の手段にて商品をご利用いただけるように致します</li>
<li>返品について：商品、サービスの性質上、理由にいかんを問わず一切の返品・返金等はお受けできません</li>
<li>表現、及び商品に関する注意書き：商品に示された表現や再現性には個人差があり、必ずしも利益や効果を保証したものではございません</li>
</ul>

	</main>


  </Osirase>
  )